.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  display: flex;
}

/* Sidebar styles */

/* Main content styles */
.main-content {
  margin-left: 220px;
  padding: 20px;
  width: calc(100% - 190px);
  transition: all 0.3s ease-in-out;
  margin-top: 50px;

}

/* Dashboard title */
.dashboard-title {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2.5rem;
  font-weight: bold;
}

.dashboard-title1 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2.5rem;
  font-weight: bold;
  margin-top: 50px;
}

/* Responsive adjustments for mobile view */
@media (max-width: 767px) {
  .main-content {
    margin-left: 0;
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 800px) {
  .main-content {
    margin-left: 220px;
    padding: 20px;
    width: calc(100% - 190px);
    transition: all 0.3s ease-in-out;
  }
}

@media (min-width: 800px) and (max-width: 900px) {
  .main-content {
    margin-left: 200px;
    padding: 20px;
    width: calc(100% - 150px);
    transition: all 0.3s ease-in-out;
  }
}

@media (min-width: 900px) and (max-width: 991px) {
  .main-content {
    margin-left: 150px;
    padding: 20px;
    width: calc(100% - 50px);
    transition: all 0.3s ease-in-out;
  }
}

@media (min-width: 992px) and (max-width: 1100px) {
  .main-content {
    margin-left: 220px;
    padding: 20px;
    width: calc(100% - 150px);
    transition: all 0.3s ease-in-out;
  }
}

@media (min-width: 1100px) and (max-width: 1199px) {
  .main-content {
    margin-left: 170px;
    padding: 20px;
    width: calc(100% - 90px);
    transition: all 0.3s ease-in-out;
  }
}

@media (min-width: 1500px) and (max-width: 1550px) {
  .main-content {
    margin-left: 160px;
    padding: 20px;
    width: calc(100% - 70px);
    transition: all 0.3s ease-in-out;
  }
}
@media (min-width: 1550px) and (max-width: 1600px) {
  .main-content {
    margin-left: 120px;
    padding: 20px;
    width: 100%;
    transition: all 0.3s ease-in-out;
  }
}

@media (min-width: 1600px) and (max-width: 1700px) {
  .main-content {
    margin-left: 120px;
    padding: 20px;
    width: 100%;
    transition: all 0.3s ease-in-out;
  }
}

@media (min-width: 1700px) and (max-width: 1800px) {
  .main-content {
    margin-left: 160px;
    padding: 0px;
    width: 100%;
    transition: all 0.3s ease-in-out;
  }
}


/* Add this CSS to your stylesheet */
.radio-group {
  display: flex;
  /* flex-direction: column; */
}

.radio-group div {
  margin-bottom: 10px;
}





.c{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 767) {
  .c{
    display:none;
  }
}









.table-responsive-custom {
  width: 100%;
  table-layout: auto;
}

.table-responsive-custom th,
.table-responsive-custom td {
  white-space: nowrap; /* Prevent wrapping on smaller screens */
}

@media screen and (max-width: 1023px) {
  .table-responsive-custom {
    display: block;
    overflow-x: auto; /* Allow horizontal scroll on small screens */
  }

  .table-responsive-custom table {
    min-width: 600px; /* Set a min-width for the table to scroll horizontally */
  }
}

@media screen and (min-width: 1024px) {
  .table-responsive-custom th,
  .table-responsive-custom td {
    white-space: normal; /* Wrap text on larger screens */
    word-wrap: break-word;
    word-break: break-word;
  }
}


